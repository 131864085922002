export default () => {
  const portalId = 'notifications';
  let element = document.getElementById(portalId);

  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', portalId);
  element.className =
    'p-4 fixed top-0 w-full flex flex-col items-center z-10 stack-4 pointer-events-none';
  document.body.appendChild(element);
  return element;
};
