import isPlainObject from 'lodash/isPlainObject';

// NOTE: For backward compatibility, we assume the default format to be "json",
//       in case encodedResponsesOptions is not a valid object, or if it is
//       completely missing.
async function decodeResponses(encodedResponses, encodedResponsesOptions) {
  let responses;
  let format = 'json';
  if (isPlainObject(encodedResponsesOptions)) {
    // eslint-disable-next-line prefer-destructuring
    if (encodedResponsesOptions.format) {
      format = encodedResponsesOptions.format;
    }
  }
  switch (format) {
    case 'json': {
      try {
        responses = JSON.parse(encodedResponses);
      } catch (err) {
        throw new Error('Unable to parse responses');
      }
      break;
    }
    default:
      throw new Error(`Unknown responses format: ${format}`);
  }
  return responses;
}

export default decodeResponses;
