import sortBy from 'lodash/sortBy';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import every from 'lodash/every';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { constant, reconcilingSelector } from '../common/utilsClient/selectors';
import Questionnaire from '../common/models/Questionnaire';
import QuestionnaireTranslation from '../common/models/QuestionnaireTranslation';
import EvaluationScope from '../common/models/EvaluationScope';
import {
  RESPONSE_SOURCE__USER_INPUT,
  RESPONSE_SOURCE__INITIAL_BINDING,
} from '../common/constants';
import { toFormValues, toResponsesArray } from '../common/utils/responses';
import { evaluateFormValuesAndProperties } from '../common/utils/questionnaire';
import { createDynamicQuestionnaireSelectors } from '../common/containers/Questionnaire';
import { fromBindingsArray } from '../common/utils/formValues';
import {
  load,
  isCompleted as createIsCompleted,
  getStaged,
  STATE_INITIAL,
} from '../store/stage';
import selectJsonObject from './selectJsonObject';
import parseQuestionnaire from './parseQuestionnaire';
import defaultQuestionFilter from './questionFilter';
import useOnline from './useOnline';
import useReconcile from '../common/utilsClient/useReconcile';
import { isMatchingQuestionnaireId } from '../common/utils/versions';
import { getRejectedParticipationsClientSide } from '../common/utils/getRejectedParticipations';

const isMoreRecent = (version1, version2) => {
  if (!version1) {
    return false;
  }
  if (!version2) {
    return true;
  }
  return version1 > version2;
};

const makeSelector = ({
  dataQuestionnaire,
  dataTranslation,
  answersSheetId,
  preferRemoteDraftIfNewer,
}) => {
  const questionnaireId = get(
    dataQuestionnaire,
    'answersSheet.questionnaire.id',
  );
  const questionnaireVariant =
    questionnaireId &&
    find(
      get(
        dataQuestionnaire,
        'answersSheet.activity.project.questionnaireVariants',
      ),
      ({ identifier, version }) => {
        return isMatchingQuestionnaireId(
          `${identifier}@${version}`,
          questionnaireId,
        );
      },
    );
  const selectQuestionnaire = parseQuestionnaire(
    constant(get(dataQuestionnaire, 'answersSheet.questionnaire.data')),
    constant(
      get(dataTranslation, 'answersSheet.questionnaire.translation.data'),
    ),
    questionnaireVariant ? questionnaireVariant.finalComputations : undefined,
  );
  const selectTranslations = constant(
    get(dataQuestionnaire, 'answersSheet.questionnaire.translations'),
  );
  const selectQuestionFilter = constant(defaultQuestionFilter);
  const selectAnswersSheetId = constant(answersSheetId);
  const selectLocalDraft = createSelector(
    load(selectAnswersSheetId),
    (staged) => {
      // NOTE: Don't treat INITIAL as a valid draft
      //       because it will only contain information
      //       about selected language.
      if (staged && staged.state === STATE_INITIAL) {
        return null;
      }
      return staged;
    },
  );
  const selectRemoteDraft = createSelector(
    selectJsonObject(get(dataQuestionnaire, 'answersSheet.draftData')),
    selectJsonObject(get(dataQuestionnaire, 'answersSheet.frameworkData')),
    selectQuestionnaire,
    (draft, framework, questionnaire) => {
      if (draft) {
        return draft;
      }
      let variables;
      let responses;
      if (framework) {
        ({ responses, variables } = framework);
      }
      if (!isEmpty(responses)) {
        return {
          previousResponses: responses,
          responses,
          variables,
        };
      }
      const evaluationScope = new EvaluationScope({
        questionnaire,
        variables: fromBindingsArray(variables),
      });
      // NOTE: Take into account that some of the initial values may be hidden
      //       because of behaviors.
      const { formValues: initialValues } = evaluateFormValuesAndProperties(
        evaluationScope.copyWithFormValues(evaluationScope.getInitialValues()),
      );
      return {
        variables,
        responses: map(toResponsesArray(initialValues), (response) => ({
          ...response,
          source: RESPONSE_SOURCE__INITIAL_BINDING,
        })),
      };
    },
  );
  const selectMostRecentDraft = createSelector(
    selectLocalDraft,
    selectRemoteDraft,
    (localDraft, remoteDraft) => {
      if (!remoteDraft) {
        return localDraft;
      }
      if (!localDraft) {
        return remoteDraft;
      }
      if (isMoreRecent(remoteDraft.version, localDraft.version)) {
        return remoteDraft;
      }
      return localDraft;
    },
  );
  const selectCurrentDraft = preferRemoteDraftIfNewer
    ? selectMostRecentDraft
    : selectLocalDraft;
  const selectCurrentResponses = createSelector(selectCurrentDraft, (draft) => {
    if (draft) {
      return draft.responses || [];
    }
    return [];
  });
  const selectLastAnsweredQuestionId = createSelector(
    selectCurrentResponses,
    (responses) => {
      const lastResponse = findLast(
        responses,
        (response) =>
          !response.source || response.source === RESPONSE_SOURCE__USER_INPUT,
      );
      return lastResponse && lastResponse.questionId;
    },
  );
  const selectVariables = reconcilingSelector(
    selectCurrentDraft,
    (currentDraft) => {
      if (!currentDraft) {
        return null;
      }
      return fromBindingsArray(currentDraft.variables);
    },
  );
  const select = createDynamicQuestionnaireSelectors({
    selectRawFormValues: createSelector(selectCurrentResponses, (responses) =>
      toFormValues(responses),
    ),
    selectQuestionnaire: createSelector(
      selectQuestionnaire,
      (questionnaire) => questionnaire || new Questionnaire({}),
    ),
    selectVariables,
  });
  const selectCurrentQuestionId = createSelector(
    selectQuestionFilter,
    selectLastAnsweredQuestionId,
    select.questionCursor(selectLastAnsweredQuestionId, undefined, {
      force: true,
    }),
    (questionFilter, lastAnsweredQuestionId, currentCursor) => {
      // NOTE: If cursor is not valid, e.g. questionnaire has not loaded yet,
      //       both nextQuestionIdWhere and firstQuestionIdWhere will return null.
      //       If lastAnsweredQuestionId turns out to be the last question of the
      //       questionnaire, nextQuestionIdWhere() will return undefined, that's
      //       we use alternative value.
      if (lastAnsweredQuestionId) {
        // TODO: Later on we can perform the "CAT query" at this stage
        //       to receive the next questionId based on current responses.
        if (currentCursor.isVisible() && currentCursor.hasErrors()) {
          return lastAnsweredQuestionId;
        }
        return (
          currentCursor.nextQuestionIdWhere(questionFilter) ||
          currentCursor.firstQuestionIdWhere(questionFilter)
        );
      }
      return currentCursor.firstQuestionIdWhere(questionFilter);
    },
  );
  const selectFinalConsentAnswer = createSelector(
    select.evaluationScope(),
    (scope) => {
      return scope.lookupAnswer('final:consent');
    },
  );
  const selectStagedWithFinalConsentAnswer = createSelector(
    getStaged,
    selectFinalConsentAnswer,
    (staged, finalConsentAnswer) => {
      return {
        ...staged,
        [answersSheetId]: {
          ...staged[answersSheetId],
          finalComputedResponses: !finalConsentAnswer
            ? []
            : [
                {
                  questionId: 'final:consent',
                  answer: finalConsentAnswer,
                },
              ],
        },
      };
    },
  );
  return createStructuredSelector({
    isFirstInActivity: createSelector(
      constant(get(dataQuestionnaire, 'my.answersSheets')),
      constant(get(dataQuestionnaire, 'answersSheet.activity.id')),
      (answersSheets, activityId) => {
        const byActivityId = groupBy(answersSheets, 'activityId');
        return isEmpty(byActivityId[activityId])
          ? null
          : findIndex(
              sortBy(byActivityId[activityId], 'orderInActivity'),
              (answersSheet) => answersSheet.id === answersSheetId,
            ) === 0;
      },
    ),
    isLastInAllActivities: createSelector(
      constant(get(dataQuestionnaire, 'my.answersSheets')),
      constant(get(dataQuestionnaire, 'my.activities')),
      selectStagedWithFinalConsentAnswer,
      (answersSheets, activities, staged) => {
        const rejectedParticipations = getRejectedParticipationsClientSide(
          answersSheets,
          activities,
          staged,
        );
        const byActivityId = groupBy(answersSheets, 'activityId');
        const filteredActivities = filter(
          activities,
          (activity) =>
            !includes(rejectedParticipations, activity.participationId),
        );
        const filteredAnswersSheets = flatMap(
          filteredActivities,
          (activity) => byActivityId[activity.id] || [],
        );
        const isCompleted = createIsCompleted(staged);
        return every(
          filteredAnswersSheets,
          (answersSheet) =>
            answersSheet.id === answersSheetId || isCompleted(answersSheet),
        );
      },
    ),
    variables: selectVariables,
    mostRecentDraft: selectMostRecentDraft,
    localDraftNeedsUpdate: createSelector(
      selectLocalDraft,
      selectRemoteDraft,
      (localDraft, remoteDraft) => {
        if (!remoteDraft) {
          return false;
        }
        if (!localDraft) {
          return true;
        }
        return isMoreRecent(remoteDraft.version, localDraft.version);
      },
    ),
    isStarted: createSelector(
      selectCurrentResponses,
      (responses) => responses.length > 0,
    ),
    isCompleted: createSelector(
      constant(get(dataQuestionnaire, 'answersSheet')),
      getStaged,
      (answersSheet, staged) => createIsCompleted(staged)(answersSheet),
    ),
    questionnaire: selectQuestionnaire,
    currentQuestionId: selectCurrentQuestionId,
    translations: selectTranslations,
    finalConsentAnswer: selectFinalConsentAnswer,
  });
};

// NOTE: This query is intentionally a little bit too broad, but thanks to this
//       it can be used to preload data for Form screen, which is using exactly
//       the same query string.
export const GET_QUESTIONNAIRE = gql`
  query GetQuestionnaire($answersSheetId: ID!) {
    my {
      id
      answersSheets {
        id
        state
        activityId
        orderInActivity
      }
      activities {
        id
        isConsent
        participationId
      }
    }
    answersSheet(id: $answersSheetId) {
      id
      state
      draftData
      frameworkData
      questionnaire {
        id
        data
        translations {
          id
          language
          languageNativeName
        }
      }
      activity {
        id
        project {
          id
          languages
          questionnaireVariants {
            version
            identifier
            finalComputations {
              name
              questionnaireVariableId
              expression
            }
          }
        }
        isConsent
      }
    }
  }
`;

export const GET_TRANSLATION = gql`
  query GetTranslation(
    $answersSheetId: ID!
    $language: String!
    $version: String
  ) {
    answersSheet(id: $answersSheetId) {
      id
      questionnaire {
        id
        translation(language: $language, version: $version) {
          id
          data
        }
      }
    }
  }
`;

export const useQuestionnaireTranslation = (answersSheetId, translationId) => {
  let translationLanguage;
  let translationVersion;
  if (translationId) {
    const chunks = translationId.split('/');
    // eslint-disable-next-line prefer-destructuring
    translationLanguage = chunks[3];
    // eslint-disable-next-line prefer-destructuring
    translationVersion = chunks[4];
  }
  const { data, loading } = useQuery(GET_TRANSLATION, {
    skip: !translationVersion || !translationLanguage,
    variables: {
      answersSheetId,
      language: translationLanguage,
      version: translationVersion,
    },
  });
  const rawTranslation = get(
    data,
    'answersSheet.questionnaire.translation.data',
  );
  let translation;
  try {
    translation = new QuestionnaireTranslation(JSON.parse(rawTranslation));
  } catch (err) {
    // ignore
  }
  return {
    translation,
    translationLoading: !!loading,
  };
};

const useQuestionnaire = (
  answersSheetId,
  translationId,
  { preferRemoteDraftIfNewer = false } = {},
) => {
  const { data: dataQuestionnaire, loading: loadingQuestionnaire } = useQuery(
    GET_QUESTIONNAIRE,
    {
      skip: !answersSheetId,
      variables: {
        answersSheetId,
      },
    },
  );
  let translationLanguage;
  let translationVersion;
  if (translationId) {
    const chunks = translationId.split('/');
    // eslint-disable-next-line prefer-destructuring
    translationLanguage = chunks[3];
    // eslint-disable-next-line prefer-destructuring
    translationVersion = chunks[4];
  }
  const { data: dataTranslation, loading: loadingTranslation } = useQuery(
    GET_TRANSLATION,
    {
      skip: !translationVersion || !translationLanguage,
      variables: {
        answersSheetId,
        language: translationLanguage,
        version: translationVersion,
      },
    },
  );
  const {
    questionnaire,
    isFirstInActivity,
    isLastInAllActivities,
    variables,
    mostRecentDraft,
    localDraftNeedsUpdate,
    isStarted,
    isCompleted,
    currentQuestionId,
    translations,
    finalConsentAnswer,
  } = useSelector(
    useMemo(
      () =>
        makeSelector({
          dataQuestionnaire,
          dataTranslation,
          answersSheetId,
          preferRemoteDraftIfNewer,
        }),
      [
        dataQuestionnaire,
        dataTranslation,
        answersSheetId,
        preferRemoteDraftIfNewer,
      ],
    ),
  );
  const projectLanguages = useReconcile(
    get(dataQuestionnaire, 'answersSheet.activity.project.languages'),
  );
  const isConsent = get(dataQuestionnaire, 'answersSheet.activity.isConsent');
  return {
    loadingQuestionnaire,
    loadingTranslation,
    loading: loadingQuestionnaire || loadingTranslation,
    questionnaire,
    isFirstInActivity,
    isLastInAllActivities,
    variables,
    mostRecentDraft,
    localDraftNeedsUpdate,
    isStarted,
    isCompleted,
    currentQuestionId,
    // NOTE: Make sure that only translations explicitly allowed in this project are included.
    translations: useReconcile(
      filter(translations, (translation) =>
        includes(projectLanguages, translation.language),
      ),
    ),
    projectLanguages,
    isConsent,
    finalConsentAnswer,
  };
};

export const GET_DRAFT_DATA = gql`
  query GetDraftData($answersSheetId: ID!) {
    answersSheet(id: $answersSheetId) {
      id
      state
      draftData
      frameworkData
    }
  }
`;

export const useLatestDraftData = (answersSheetId) => {
  const online = useOnline();
  // NOTE: We are not extracting any data from it, we just use it
  //       to force refreshing cache when network is online.
  useQuery(GET_DRAFT_DATA, {
    fetchPolicy: 'network-only',
    skip: !online || !answersSheetId,
    variables: {
      answersSheetId,
    },
  });
};

export default useQuestionnaire;
