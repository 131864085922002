import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import find from 'lodash/find';
import map from 'lodash/map';
import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

export const ENGLISH_VALUE = 'en';

export const getNavigatorLanguages = () => {
  const languages = [];
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      for (let i = 0; i < navigator.languages.length; i += 1) {
        languages.push(navigator.languages[i]);
      }
    }
    if (navigator.userLanguage) {
      languages.push(navigator.userLanguage);
    }
    if (navigator.language) {
      languages.push(navigator.language);
    }
  }
  return map(languages, (language) => language.substr(0, 2));
};

export const getPreferredLanguage = (
  languagePreference,
  availableLanguages,
  defaultLanguage = null,
) => {
  if (isEmpty(languagePreference)) {
    return defaultLanguage;
  }
  const n = languagePreference.length;
  for (let i = 0; i < n; i += 1) {
    const language = languagePreference[i];
    if (includes(availableLanguages, language)) {
      return language;
    }
  }
  return defaultLanguage;
};

export const getPreferredTranslation = (
  languagePreference,
  availableTranslations,
  defaultLanguage = null,
) => {
  const language = getPreferredLanguage(
    languagePreference,
    map(availableTranslations, 'language'),
    defaultLanguage,
  );
  if (!language) {
    return null;
  }
  return language
    ? find(availableTranslations, {
        language,
      })
    : null;
};

export const initI18next = ({
  supportedLanguages,
  supportedNamespaces,
  languageDetector,
}) => {
  i18next.use(Backend).use(initReactI18next);
  if (languageDetector) {
    i18next.use(languageDetector);
  }
  i18next.init(
    {
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      interpolation: {
        escapeValue: false,
      },
      nonExplicitWhitelist: true,
      lng: languageDetector
        ? null
        : getPreferredLanguage(
            getNavigatorLanguages(),
            supportedLanguages,
            ENGLISH_VALUE,
          ),
      whitelist: supportedLanguages,
      ns: supportedNamespaces,
      fallbackLng: ENGLISH_VALUE,
      defaultNS: 'common',
      // debug: true,
    },
    // eslint-disable-next-line no-console
    (err) => err && console.error(err),
  );
  return i18next;
};
