import isNil from 'lodash/isNil';
import isFinite from 'lodash/isFinite';
import { marked } from 'marked';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';
import getDynamicQuestionnaire from './getDynamicQuestionnaire';
import EvaluationScope from '../../models/EvaluationScope';
import { htmlToPlainText2 } from '../htmlToPlainText';

const renderer = new marked.Renderer();

/**
 * Replaces all Markdown statements (including html tags) with plain text.
 * @param {string} text
 * @returns {string}
 */
function defaultRenderMarkdown(text) {
  const textWithHtml = marked.parseInline(text, {
    renderer,
  });
  return htmlToPlainText2(textWithHtml);
}

function presentAnswersSheet(
  questionnaire,
  answersSheet,
  {
    includeMissing = false,
    filterQuestions = null,
    preserveNumbers = false,
    // NOTE: This flag is to make sure what formatted responses include as much information as possible e.g. values, labels, etc..
    // It's enabled by default because users of the provider portal usually need responses that include lots of data
    // But it's useful to disable it for external interfaces such as the patient facing app
    // See https://theclinician.atlassian.net/browse/TECH-1114?focusedCommentId=23860
    forInternalUse = true,
    renderMarkdown = defaultRenderMarkdown,
  } = {},
) {
  const evaluationScope = EvaluationScope.create({
    questionnaire,
    answersSheet,
  });
  const { formValues, properties } =
    evaluateFormValuesAndProperties(evaluationScope);
  const dynamicQuestionnaire = getDynamicQuestionnaire(
    questionnaire,
    properties,
  );
  const results = [];
  dynamicQuestionnaire.forEachQuestion(
    (question) => {
      const answer = formValues[question.id];
      const formattedAnswer =
        preserveNumbers && answer && isFinite(answer.value)
          ? answer.value
          : question.formatAnswer(answer, renderMarkdown, forInternalUse);
      // NOTE: In this case, "nil" means either empty answer or
      //       problematic question type, e.g. collection
      if (includeMissing || !isNil(formattedAnswer)) {
        results.push({
          label: renderMarkdown(question.getTitle()),
          // NOTE: We are not running answer through htmlToPlainText here
          //       so if the value comes from the patient input, the consumer
          //       of results will need to ensure that it's sanitized
          //       appropriately.
          value: formattedAnswer,
          answer,
          questionType: question.type,
          questionId: question.id,
        });
      }
    },
    {
      stopRecursion: (question) => question.isCollection(),
      filterQuestions: (question) => {
        if (!question.hasPresentableValue()) {
          return false;
        }
        if (typeof filterQuestions !== 'function') {
          return true;
        }
        return !!filterQuestions(question);
      },
    },
  );
  return results;
}

export default presentAnswersSheet;
